import React from 'react';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/privacy-policy';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Wrapper>
        <h1>Privacy Policy</h1>
        <p>
          This website does not share personal information with third parties
          nor do we store any information about your visit to this website other
          than to analyze and optimize your content and reading experience or to
          provide services through the website such as the Live Chat feature.
        </p>
        <p>
          You can turn off the use of cookies at anytime by changing your
          specific browser settings.
        </p>
        <p>
          We are not responsible for republished content from this website on
          other blogs or websites without our permission.
        </p>
        <p>
          This privacy policy is subject to change without notice and was last
          updated on May 30th, 2019. If you have any questions feel free to
          contact me directly here:{' '}
          <a href="mailto:scitechcarpets@gmail.com">scitechcarpets@gmail.com</a>
          .
        </p>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPolicyPage;
